export default class SlickVehicleGallery {
    public static init() {
		if ($('.slick--vehicle-gallery-main').length > 0) {
			$('.slick--vehicle-gallery-main').slick({
				slidesToShow: 1,
				slidesToScroll: 1,
				arrows: false,
				autoplay: false,
				mobileFirst: true,
				responsive: [
					{
						breakpoint: 480,
						settings: {
							slidesToShow: 2
						}
					}
				]
			});
			$('.slick--vehicle-gallery-thumbs').slick({
				slidesToShow: 2,
				slidesToScroll: 1,
				arrows: true,
				prevArrow: "<span class='slick-prev main'>&lt;</span>",
				nextArrow: "<span class='slick-next main'>&gt;</span>",
				mobileFirst: true,
				responsive: [
					{
						breakpoint: 480,
						settings: {
							slidesToShow: 4,
							autoplay: true
						}
					}
				],
				dots: false,
				centerMode: false,
				focusOnSelect: true,
				autoplay: true,
				autoplaySpeed: 3000
			});
			$('.slick--vehicle-gallery-main').each(function () {
				(<any>$(this)).magnificPopup({
					delegate: 'a',
					type: 'image',
					gallery: {
						enabled: true
					}
				});
			});
			$('.slick--vehicle-gallery-thumbs').each(function () {
				(<any>$(this)).magnificPopup({
					delegate: 'a',
					type: 'image',
					gallery: {
						enabled: true
					}
				});
			});
		}
	}
}